import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import CollapsibleContainer from './CollapsibleContainer'
import {Row, Col, Wrapper, Container, ContainerPadding, ResponsiveImage} from '../theme'
import Button from './Button'
import BlockContent from '@sanity/block-content-to-react';
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import cloneDeep from 'lodash/cloneDeep';


export default ()=>{
    const snapshots = useSelector(state => state.data.snapshots);
    const client = useSelector(state => state.data.client);
    const dispatch = useDispatch();
    const imagesets = useSelector(state => state.data.imagesets);

    const load = (snapshot)=>{
        dispatch({type: DATA_ACTIONS.SET_CURRENT_PARAMETERS, currentParameters: cloneDeep(JSON.parse(snapshot.data))});

        imagesets.forEach((imageset, idx)=>{
            if (imageset._id == snapshot.imageset){
                dispatch({type: DATA_ACTIONS.SET_CURRENT_IMAGE_SET, currentImageSet: {idx}});
            }
        })

        
    }

    if (!snapshots || !snapshots.length){
        return null;
    } else {
        return <CollapsibleContainer title="snapshots" beginOpen>
            <Row center="xs">
                {snapshots.map((snapshot, idx)=>{
                    return <Col xs={12} sm={6} key={idx}>
                        <CollapsibleContainer title={snapshot.title || "no title"} beginOpen>
                            {snapshot.screenshot?<ResponsiveImage src={snapshot.screenshot} />:null}
                            {snapshot.description?<BlockContent blocks={snapshot.description}  />:null}
                            <br/>
                            <Button text="load snapshot" onClick={()=>{load(snapshot)}}/>
                            
                        </CollapsibleContainer>
                    </Col>
                })}
            </Row>
         
        </CollapsibleContainer>
    }
}