
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import LogoMaker from './sketch'
import styled from 'styled-components'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import {VARNAMES} from '../services/parameters'
import cloneDeep from 'lodash/cloneDeep';
import {InlineText, Grid, Row, Col, Title, Wrapper, ResponsiveImage} from '../theme'
import CollapsibleContainer from './CollapsibleContainer'
import BlockContent from '@sanity/block-content-to-react';
import Button from './Button'
import SnapshotMaker from './Snapshotmaker'
import Snapshots from './Snapshots'

const Toggle = ({keyword})=>{
    const data = useSelector(state => state.data.currentParameters);
    const dispatch = useDispatch();

    const setvalue = (value)=>{
        data[keyword] = !data[keyword];
        dispatch({type: DATA_ACTIONS.SET_CURRENT_PARAMETERS, currentParameters: cloneDeep(data)})
    }

    return <Row>
    <Col xs={3} style={{marginBottom: "10px"}}>
        <InlineText>
            <b>
                {keyword.toString().replace("Symbol(", "").replace(")", '').toUpperCase()}
            </b>
        </InlineText>
    </Col>
    <Col xs={8}>
        <label className="switch">
        <input type="checkbox" type="checkbox" defaultChecked={data[keyword]} onChange={setvalue} />
        <span className="slider"></span>
        </label> 
    </Col>
</Row> 
}

const Slider = ({keyword})=>{
    const data = useSelector(state => state.data.currentParameters);
    const dispatch = useDispatch();

    const setvalue = (value)=>{
        data[keyword].value = value;
        dispatch({type: DATA_ACTIONS.SET_CURRENT_PARAMETERS, currentParameters: cloneDeep(data)})
    }

    return <Row>
        <Col xs={3} style={{marginBottom: "10px"}}>
            <InlineText>
                <b>
                    {keyword.toString().replace("Symbol(", "").replace(")", '').toUpperCase()}
                </b>
            </InlineText>
        </Col>
        <Col xs={7}>
        <input type="range" min={data[keyword].min} max={data[keyword].max} id="myRange" 
                    value={data[keyword].value} 
                    onChange={e=>setvalue(e.target.value)} />
        </Col>
        <Col xs={2}>
        <InlineText>
                {data[keyword].value}
                </InlineText>
        </Col>
    </Row> 
}

const Variables = ()=>{
    
    const current = useSelector(state => state.data.currentImageSet);
    const imagesets = useSelector(state => state.data.imagesets);

    return (<CollapsibleContainer title="Variables" beginOpen>
            <Slider keyword={VARNAMES.GRID} />
            <br/>
            <InlineText>
                <b>
                    ROTATION
                </b>
            </InlineText>
            <br/><br/>
            <Toggle keyword={VARNAMES.RANDOM} />
            <Slider keyword={VARNAMES.SEED} />
            <Toggle keyword={VARNAMES.NOISE} />
            <Slider keyword={VARNAMES.VARIANCEX} />
            <Slider keyword={VARNAMES.VARIANCEY} />
            <Toggle keyword={VARNAMES.ROTATEX} />
            <Toggle keyword={VARNAMES.ROTATEY} />
            <br/>
            <InlineText>
                <b>
                    SELECTION
                </b>
            </InlineText>
            <br/><br/>
            <Toggle keyword={VARNAMES.RANDOMS} />
            <Slider keyword={VARNAMES.SEEDS} />
            <Toggle keyword={VARNAMES.NOISES} />
            <Slider keyword={VARNAMES.VARIANCEXS} />
            <Slider keyword={VARNAMES.VARIANCEYS} />
            <Slider keyword={VARNAMES.CENTERIDX} />
            <br/>
            <InlineText>
                <b>
                    CUTOFF
                </b>
            </InlineText>
            <Toggle keyword={VARNAMES.CUTOFF} />
            <Slider keyword={VARNAMES.SEEDC} />
            <Toggle keyword={VARNAMES.INVERT} />
            <Slider keyword={VARNAMES.VARIANCEXC} />
            <Slider keyword={VARNAMES.VARIANCEYC} />
            <br/><br/>
            {current?<InlineText>
                <b>Current image set: </b> {imagesets[current.idx].title}
            </InlineText>:null}
            
    </CollapsibleContainer>)
    }

const ImageSet = ({data, idx})=>{
    const current = useSelector(state => state.data.currentImageSet);
    const dispatch = useDispatch();

    return <CollapsibleContainer title={data.title}>
        <InlineText>
            <BlockContent blocks={data.description}  />
            {data.images.map(image=><ResponsiveImage src={`${image}`} key={image} />)}
        </InlineText>
        {(idx)? <Button text="select set" onClick={()=>{dispatch({type: DATA_ACTIONS.SET_CURRENT_IMAGE_SET, currentImageSet: {idx: idx}})}} />:null}
    </CollapsibleContainer>
}

const ImageSets = ()=>{
    const current = useSelector(state => state.data.currentImageSet);
    const imagesets = useSelector(state => state.data.imagesets);

    if (!imagesets || !current){
        return null;
    } else {
        return <CollapsibleContainer title="Image sets">
                    <Row>
                        <Col xs={12}>
                            <Title>
                                Selected image set
                            </Title>

                            <ImageSet data={imagesets[current.idx]} />

                        </Col>
                        <Col xs={12}>
                            <Title>
                                Other image sets
                            </Title>
                            {imagesets.map((is, idx)=> idx==current.idx?null:<ImageSet data={is} idx={idx} key={is.title} /> )}
                        </Col>
                    </Row>
        </CollapsibleContainer> 
    }

}


export default ()=>{
    const [width, setWidth] = useState(null);
    const containerRef = useRef(null);
    const data = useSelector(state => state.data.currentParameters);
    const images = useSelector(state => state.data.currentImageSet);
    const imagesets = useSelector(state => state.data.imagesets);


    useEffect(() => {
        if (containerRef && containerRef.current && containerRef.current.offsetWidth){
        setWidth(containerRef.current.offsetWidth)
        }
    });

    return <Grid>
                <Row>
                    <Col xs={12}>
                        <Title>
                            POORTERSLOGO
                        </Title>
                    </Col>
                    <Col xs={12} sm={10} md={6} lg={false}>
                        <Variables />
                    </Col>
                    <Col xs={12} sm={10} md={6} lg={false}>
                        <ImageSets />
                    </Col>
                    <Col xs={false} lg={4}>
                        <Variables />
                        <ImageSets />
                    </Col>

                    <Col xs={12} lg={8}>
                        <div style={{height: "28px"}}>&nbsp;</div>
                    <div style={{width: "100%"}} ref={containerRef}>
                        {width && data && images && imagesets? 
                                <LogoMaker 
                                    width={width -20} 
                                    />
                                  : null}
                        </div>
                    </Col>
                    
                    <Col xs={12} lg={4}>
                        <SnapshotMaker />
                    </Col>
                    <Col xs={12} lg={8}>
                        <Snapshots />
                    </Col>

                </Row>
            </Grid>
}



