import sanityClient from '@sanity/client'
import envi from './environment'
import {settings} from './connector_settings.json'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import toast from './toast'
import imageUrlBuilder from '@sanity/image-url'

const prevFetched = {};

export const client = (dispatch)=>{
    const environment = envi();
    let _ = settings;
    const sc = sanityClient({
        projectId: _.projectId,
        dataset: _.dataset,
        token: _.token, // or leave blank to be anonymous user
        ignoreBrowserTokenWarning: true,
        useCdn: true
    })

    const builder = imageUrlBuilder(sc)

    const squareImage = (url, width)=>{
        return builder.image(url).width(width).height(width).url();
    }

    const fetch = (query)=>{
        return new Promise((resolve, reject)=>{
            if (prevFetched[query]){
                if (environment.dev){
                    console.log("reused cached query");
                }
                resolve(prevFetched[query]);
            } else {
                sc.fetch(query)
                .then((data)=>{
                    prevFetched[query] = data;
                    resolve(data);
                }).catch(reject);
            }
        })
    }
    

    const getImageSets = ()=>{
        fetch('*[_type == "imageset"] | order(_createdAt desc){_id, title, description, "images":images[].asset->url}')
        .then((imagesets)=>{
            toast(`${imagesets.length} imagesets loaded`);
            if (imagesets && imagesets.length){
                dispatch({type: DATA_ACTIONS.SET_IMAGE_SETS, imagesets})
                dispatch({type: DATA_ACTIONS.SET_CURRENT_IMAGE_SET, currentImageSet: {idx: 0}})
            }
        })
    }

    const uploadImage = (filename, blob)=>{
        return sc.assets.upload('image', blob, {contentType: 'image/png', filename})
    }

    const makeImageReference = (_ref)=>{
        return  {
            _type: 'image',
            asset : {
                _type: "reference",
                _ref
            }
        }
    }

    const saveSnapShot = (data, title, description, imagedata, imageset)=>{
        return new Promise((resolve, reject)=>{

            uploadImage(title+'.pngg', imagedata)
                .then((document)=>{
                    let screenshot = makeImageReference(document._id);
                    sc.create({
                        data, title, description, screenshot, imageset,
                        _type: "snapshot"
                    }).then(snapshot=>{
                        toast(`${title} was created`)
                        resolve();
                    }).catch(c=>reject());      
            })
        })
    }

    const getSnapShots = ()=>{
        fetch('*[_type == "snapshot"] | order(_createdAt desc){_id, data, title, description, imageset, "screenshot":screenshot.asset->url}')
        .then((snapshots)=>{
            toast(`${snapshots.length} snapshots loaded`);
            if (snapshots && snapshots.length){
                dispatch({type: DATA_ACTIONS.SET_SNAPSHOTS, snapshots})
            }
        })
    }
    

    if (environment.dev){
        environment.printstatus()
    } 

    (()=>{
        getImageSets();
        getSnapShots();
    })()

    return {
        fetch,
        environment,
        squareImage, saveSnapShot,
    }
}

export const FETCH_STATES = Object.freeze({
    IDLE: Symbol("fetch/idle"),
    BUSY: Symbol("fetch/busy"),
    ERROR: Symbol("fetch/error"),
});

export default client;

