import React, {useState} from 'react'
import styled from 'styled-components';
import {Text} from '../theme'

const Input = styled.input`
width: 100%;
padding: 5px;
box-sizing: border-box;
font-size: 15px;
`

export default ({title, NoBottom, value, setValue})=>{
    title = title || ''
    NoBottom = NoBottom || false;

    return <table style={{border: "solid black 2px", width: "100%", borderBottom: NoBottom?"0px":"solid black 2px"}}>
    <tr>
        <td style={{width: 'auto'}}>
            <Text>
                <b>{title}</b>
            </Text>
        </td>
        <td style={{width: "90%"}}>
            <Input type="text" name="name" value={value} onChange = {e => setValue(e.target.value)} />
        </td>
        <td> &nbsp;&nbsp; </td>
    </tr>
</table>
}