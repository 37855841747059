import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from 'react-redux'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import Sketch from "react-p5";
import {VARNAMES} from '../services/parameters'
import Chance from 'chance'

export default ({width})=>{
    const [grid, rotatex, rotatey, random, seed, noise, variancex, variancey, currentImageSet, imagesets, randoms, seeds, noises, variancexs, varianceys, centerIdx, cutoff, cuttofInvert, variancexc, varianceyc, seedc] 
            = useSelector(state => {
                const pm = state.data.currentParameters;
                return [pm[VARNAMES.GRID].value, 
                pm[VARNAMES.ROTATEX], 
                pm[VARNAMES.ROTATEY],
                pm[VARNAMES.RANDOM],
                pm[VARNAMES.SEED].value, 
                pm[VARNAMES.NOISE],
                pm[VARNAMES.VARIANCEX].value,
                pm[VARNAMES.VARIANCEY].value,
                state.data.currentImageSet, 
                state.data.imagesets,
                pm[VARNAMES.RANDOMS],
                pm[VARNAMES.SEEDS].value, 
                pm[VARNAMES.NOISES],
                pm[VARNAMES.VARIANCEXS].value,
                pm[VARNAMES.VARIANCEYS].value,
                pm[VARNAMES.CENTERIDX].value,
                pm[VARNAMES.CUTOFF],
                pm[VARNAMES.INVERT],
                pm[VARNAMES.VARIANCEXC].value,
                pm[VARNAMES.VARIANCEYC].value,
                pm[VARNAMES.SEEDC].value,
            ]
            });
    let rendered = false;
    const [preloadedImages, setPreloadedImages] = useState(null);
    const dispatch = useDispatch();
    let chance = new Chance(seed);
    let chances = new Chance(seeds);
    
    useEffect(() => {
        rendered = false;
        chance = new Chance(seed);
        chances = new Chance(seeds);
    });

    const preload = (p5)=>{
        const pli = [];
        imagesets.forEach(imageset=>{
            let series = [];
            imageset.images.forEach(image=>{
                series.push(p5.loadImage(image));
            })
            pli.push(series);
        })
        setPreloadedImages(pli);
    }
    
    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(width, width).parent(canvasParentRef); 
    };

    const getGridCoordinate =(x, y)=>{
        const calc = val=>Math.floor(val/width * grid) * width/grid
        return {x: calc(x), y: calc(y)}
    }

    const getGridCoordinateFromNormalized =(x, y)=>{
        const calc = val=>Math.floor(val * grid) * width/grid
        return {x: calc(x), y: calc(y)}
    }

    const getGridMouseCoordinate = (p5)=>{
        return getGridCoordinate(p5.mouseX, p5.mouseY);
    }

    const getNormalizedMouseCoordinate = (p5)=>{
        return {x: p5.mouseX/width, y: p5.mouseY/width}
    }

    const mouseReleased = (p5)=>{
        // console.log('allo')
        // p5.saveFrames('out', 'png', 1, 1, data => {
        //     console.log(data);
        // });
    }

    const getRotationData = (p5)=>{
        const pi = p5.PI;
        let rotations = [pi];
        let max = 0;

        if (rotatex && rotatey){
            max = 3
            rotations = [0, pi/2, pi, pi/2*3];
        } else if (rotatex || rotatey){
            max = 1;
            if (rotatex){
                rotations = [0, pi/2];
            } else {
                rotations = [0, pi];
            }
        } 
        return {rotations, max};
    }

    const getRandomRotation= (p5)=>{
        const rd =  getRotationData(p5);
        let val = chance.integer({ min: 0, max: rd.max});
        return rd.rotations[val];
    }

    const getNoiseRotation = (p5, x, y)=>{
        const rd =  getRotationData(p5);
        let nv = Math.floor(p5.noise(x*variancex/100, y*variancey/100) * rd.rotations.length-.01);
        return rd.rotations[nv];
    }

    const getIndex = (p5, x, y)=>{
        const length = preloadedImages[currentImageSet.idx].length;
        if (randoms){
            return chances.integer({ min: 0, max: length-1});
        } else if (noises){
            return Math.floor((p5.noise(x*variancexs, y*varianceys) * length-.01)+centerIdx/100*length)%length;
        }
        
        return 0;
    }

    const getCutoff = (p5, x, y)=>{
        if (cutoff){
            return (p5.noise(x*variancexc/100, y*varianceyc/100) > 0.5) == cuttofInvert ;
        } else {
            return false;
        }
    }

    const draw = (p5) => {
        const drawHexa =(x, y)=>{
            let scale = width/grid/2;
            p5.beginShape();
            for(let rad = 0; rad < 2*p5.PI; rad+=2*p5.PI/6){
                p5.vertex(x +scale +p5.cos(rad)*scale, y+scale+p5.sin(rad)*scale);
            }
            p5.endShape();
        }
        
        const drawGrid = ()=>{
            for (let x = 0; x < grid; x++){
                for (let y = 0; y < grid; y++){
                    const tx = x * width/grid;
                    const ty = y * width/grid;
                    let w = width/grid;
                    if (preloadedImages && preloadedImages.length && !getCutoff(p5, x+seedc/100, y+seedc/100)){
                            let index = getIndex(p5, x+seeds/100, y+seeds/100);
                            p5.push();
                            p5.translate(tx, ty);
                            if (random){
                                p5.rotate(getRandomRotation(p5));
                            } else if (noise){
                                p5.rotate(getNoiseRotation(p5, x+seed/100, y+seed/100))
                            }
                            p5.translate(-w/2,-w/2);
                            p5.image(preloadedImages[currentImageSet.idx][index], 0, 0, w, w);
                            p5.pop();

                    }
                }
            }
        }

        const drawMouse = ()=>{
            p5.stroke("black")
            let coo = getGridMouseCoordinate(p5);
            p5.ellipse(coo.x, coo.y, 20);
        }

        if (!rendered){
            chance = new Chance(seed);
            p5.background('white');
            drawGrid();
            rendered = true;
            
        }
    };
   
    
    return <Sketch preload={preload} setup={setup} draw={draw} mouseReleased={mouseReleased} />;
    
  }