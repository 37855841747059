import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import CollapsibleContainer from './CollapsibleContainer'
import {Row, Col, Wrapper, Container, ContainerPadding} from '../theme'
import Button from './Button'
import LineInput from './LineInput'
import TextEditor from './TextEditor'
import { toast } from 'react-toastify'
import ImageDrop from './ImageDrop'

export default ()=>{
    const [title, setTitle] = useState("...");
    const [description, setDescription] = useState(null);
    const [imageData, setImageData] = useState(null);
    const data = useSelector(state => state.data.currentParameters);
    const client = useSelector(state => state.data.client);
    const current = useSelector(state => state.data.currentImageSet);
    const imagesets = useSelector(state => state.data.imagesets);
    

    const save = ()=>{
        
            client.saveSnapShot(JSON.stringify(data), title, description, imageData, imagesets[current.idx]._id)
            .then(a=>{
                setTitle('...');
                setDescription(null);
            })
        
    }

    if (!data || !client){
        return null;
    } else {
        return (
            <CollapsibleContainer title="Snapshot maker">
                <LineInput title="title" value={title} setValue={setTitle} NoBottom/>
                <Wrapper>
                    <TextEditor value={description} setValue={setDescription}/>
                </Wrapper>
                <br/>
                <Wrapper>
                    <ContainerPadding>
                        <ImageDrop setTitle={()=>{}} setValue={setImageData} text="Drag and drop screenshot here, or click to select an image" />
                    </ContainerPadding>
                </Wrapper>
                <br/>
                <Button text="save" onClick={save} />
            </CollapsibleContainer>
        )
    }

    
}