import React, {useEffect} from 'react';
import './App.css';
import { Provider, useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sanityclient from '../services/client';
import { css } from 'glamor';
import Home from './Home'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import Store from "../Store";

const DataHelper = () => {
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.client && dispatch){
      const cli = sanityclient(dispatch);
      toast.configure()
      dispatch({ type: DATA_ACTIONS.SET_CLIENT, client: cli});
    } 
  })
  return (
    null
  )
}

const Toaster = ()=>{

  const CloseButton = ({ YouCanPassAnyProps, closeToast }) => (
    <i onClick={closeToast}></i>
  );
  return <ToastContainer
    hideProgressBar={true}
    pauseOnFocusLoss={false}
    toastClassName="toastie"
    closeButton={<CloseButton/>}
  />
}

function App() {
  
  

  return (

    <Provider store={Store}>
    <DataHelper />
    <Toaster />
    <Home />
    </Provider>
  );
}

export default App;
