import '../services/parameters'
import parameters from '../services/parameters';

export const ACTIONS = Object.freeze({
    SET_CLIENT: Symbol("data/client"),
    SET_IMAGE_SETS: Symbol("data/imagesets"),
    SET_CURRENT_IMAGE_SET: Symbol("data/currentumageset"),
    SET_SNAPSHOTS: Symbol("data/snapshots"),
    SET_CURRENT_PARAMETERS: Symbol("data/currentparameters"),
    SET_IMAGE: Symbol('data/image')
});

const initialState = {
  client: null,
  imagesets: null,
  snapshots: 30,
  currentParameters : parameters(),
  currentImageSet : null,
};

export default (state = initialState, action) => {
  
  switch (action.type) {
    case ACTIONS.SET_CLIENT: {
      return {
        ...state,
        client: action.client
      };
    }
    case ACTIONS.SET_IMAGE_SETS: {
      return {
        ...state,
        imagesets: action.imagesets
      };
    }
    case ACTIONS.SET_SNAPSHOTS: {
      return {
        ...state,
        snapshots: action.snapshots
      };
    }
    case ACTIONS.SET_CURRENT_PARAMETERS: {
      return {
        ...state,
        currentParameters: action.currentParameters
      };
    }
    case ACTIONS.SET_CURRENT_IMAGE_SET: {
      return {
        ...state,
        currentImageSet: action.currentImageSet
      };
    }
    default: {
      return {
        ...state
      };
    }
  } 
};
