
export const VARNAMES = {
    GRID: "grid",
    SEED: "seed",
    ROTATEX: "rotatex",
    ROTATEY: "rotatey",
    RANDOM: "random",
    NOISE: "noise",
    VARIANCEX: "noise/x",
    VARIANCEY: "noise/y",
    SEEDS: "seedS",
    RANDOMS: "randomS",
    NOISES: "noiseS",
    VARIANCEXS: "noise/xS",
    VARIANCEYS: "noise/yS",
    CENTERIDX: "center/idx",
    CUTOFF: "cutoff",
    SEEDC: "seedC",
    INVERT: "invert",
    VARIANCEXC: "noise/xC",
    VARIANCEYC: "noise/yC",

}

export default ()=>{
    const data = {};

    const add = (name, min, max, value)=>{
        data[name] = { min, max, value}
    }

    const addToggle = (name, value)=>{
        data[name] = value;
    }

    add(VARNAMES.GRID, 1, 100, 30);
    addToggle(VARNAMES.RANDOM, false);
    add(VARNAMES.SEED, 1, 9999, 1232);
    addToggle(VARNAMES.NOISE, true);
    add(VARNAMES.VARIANCEX, 0, 100, 1);
    add(VARNAMES.VARIANCEY, 0, 100, 1);
    addToggle(VARNAMES.ROTATEX, true);
    addToggle(VARNAMES.ROTATEY, true);
    addToggle(VARNAMES.RANDOMS, false);
    add(VARNAMES.SEEDS, 1, 9999, 1232);
    addToggle(VARNAMES.NOISES, true);
    add(VARNAMES.VARIANCEXS, 0, 100, 1);
    add(VARNAMES.VARIANCEYS, 0, 100, 1);
    add(VARNAMES.CENTERIDX, 0, 100, 0);
    addToggle(VARNAMES.CUTOFF, false);
    addToggle(VARNAMES.INVERT, false);
    add(VARNAMES.VARIANCEXC, 0, 100, 1);
    add(VARNAMES.VARIANCEYC, 0, 100, 1);
    add(VARNAMES.SEEDC, 1, 9999, 1232);
    

    return data;
}